<template>
  <div class="select-page bg-wrapper">
    <div class="page-top">
      <div class="page-title">{{ common.selectPage }}</div>
      <div
        class="systerm-box"
        v-for="(item, index) in list"
        :key="item.id"
        @click="handleTo(item)"
      >
        <div class="bg-class">
          <img :src="item.logo" :class="`img-${index + 1}`" />
          <div class="name">{{ common.systermList[index] }}</div>
        </div>
      </div>
    </div>
    <div class="page-bottom">
      <div class="icon-list">
        <svg
          class="icon icon-item"
          aria-hidden="true"
          v-for="item in list1"
          :key="item.id"
          @click="handleTo(item)"
        >
          <use :xlink:href="item.logo"></use>
        </svg>
      </div>
      <div class="icpn-box">
        <div class="icpn" translate="no">© 2022 Starfish Labs.</div>
        <div class="aggre" @click="handleTo(protocol)">{{ $t('common.xieyi') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      list: [
        {
          id: 1,
          logo: require('../../assets/images/starfishdao.png'),
          name: '',
          url: ''
        },
        {
          id: 2,
          logo: require('../../assets/images/logo.png'),
          name: '',
          url: 'https://dapp.sfos.io/'
        }
      ],
      list1: [
        {
          id: 3,
          logo: '#shenqinghuibaobaobei',
          url: 'https://t.me/StarFishOs'
        },
        {
          id: 4,
          logo: '#tuite_twitter43',
          url: 'https://twitter.com/StarFishOs'
        },
        {
          id: 5,
          logo: '#discord1',
          url: 'https://discord.com/invite/StarFishOs'
        },
        {
          id: 6,
          logo: '#medium-circle-fill',
          url: 'https://medium.com/@StarFishOs'
        }
      ],
      protocol: {
        url: 'https://dao.sfos.io/doc/starfish_dapp_user_protocol.pdf'
      }
    }
  },
  computed: {
    common () {
      return this.$t('common')
    }
  },
  methods: {
    handleTo (item) {
      if (item.url) {
        window.open(item.url)
      } else {
        this.$gbUtils.handleToPage('/dao')
      }
    }
  },
  mounted () {
    this.$emit('changeLoading', false)
  }
}
</script>

<style scoped lang="scss">
.select-page {
  text-align: center;
  .page-top {
    width: 100%;
    position: relative;
    padding: 40px 0 0 0;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // background: $linear-bg2;
      border-radius: 126px;
      opacity: 0.35;
      filter: blur(50px);
      z-index: 0;
    }
    * {
      position: relative;
      z-index: 1;
    }
    .page-title {
      font-size: $font-20;
      font-weight: 600;
    }
    .systerm-box {
      width: 245px;
      height: 245px;
      padding: 4px;
      margin: 40px auto auto;
      color: $color-blue1;
      font-size: $font-16;
      // box-shadow: 0px 10px 18px 0px #161310,
      //   0px 0px 5px 0px rgba(40, 125, 193, 0.5);
      background: url('../../assets/images/systerm-bg.png');
      background-size: 100% auto;
      border-radius: 25px;
      // background: linear-gradient(
      //   180deg,
      //   rgba(161, 161, 161, 1),
      //   rgba(170, 170, 170, 1),
      //   rgba(147, 147, 147, 1),
      //   rgba(153, 153, 153, 1),
      //   rgba(179, 177, 177, 1),
      //   rgba(143, 143, 143, 1),
      //   rgba(158, 158, 158, 1),
      //   rgba(172, 172, 172, 1),
      //   rgba(148, 148, 148, 1),
      //   rgba(193, 189, 189, 1),
      //   rgba(180, 180, 180, 1),
      //   rgba(198, 198, 198, 1),
      //   rgba(221, 216, 216, 1)
      // );
      .bg-class {
        width: 100%;
        height: 100%;
        // background: linear-gradient(180deg, #e5e5ea 0%, #8e8e95 100%);
        border-radius: 22px;
        display: flex;
        align-items: center;
        // justify-content: space-evenly;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 15px;
        .name {
        }
      }
      img {
        width: 100px;
        height: auto;
        margin-bottom: 25px;
      }
      .img-1 {
        width: 80px;
      }
    }
  }
  .page-bottom {
    padding: 20px 60px 30px 60px;
    color: $main-color1;
    .icon-list {
      display: flex;
      justify-content: space-between;
      .icon-item {
        width: 25px;
        height: 25px;
      }
    }
    .icpn-box {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}
</style>
